import Constant from "../constants/schemaConstants";
import { transform, transformLeftAnchor, transformTopAnchor } from "../../utils/Transform";
import { getEnv } from "../../utils/Helper";
const initialState: any = {
  home: [],
  preference: [],
  sizing_input: [],
  loading: false,
  calculation: {},
  sizing_results: {},
  calc_loading: false,
  left_anchor: [],
  right_anchor: [],
  top_anchor: [],
  production_list: [],
  languageList: [],
  defaultNeId: 0,
  activeSession: null,
  headerData: {},
  uomData: [],
  currentSizingId: "",
  preferences:{
    defaultEnglish:{},
    defaultMetric:{},

    allOptions:{},
    englishOptions:{},
    metricOptions:{},

    metricValue:{},
    englishValue:{},
    allValue:{}
  },
  userPreferencesValue:{},
  userInfo: {},
  userId:"",
  allSizing: [],
  sizingInputs: {},
  reportPayload: [],
  bu_code: getEnv("REACT_APP_BU_CODE"),
  loginSate: false,
  alertErrorMsg: "",
};

export function schemaReducer(state = initialState, action: any) {
  switch (action.type) {
    case Constant.UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case Constant.STORE_SCHEMA:
    case Constant.STORE_SIZING_RESULTS:
    case Constant.STORE_LEFT_ANCHOR:
    case Constant.STORE_RIGHT_ANCHOR:
    case Constant.STORE_PRODUCTION_LIST:
    case Constant.STORE_LANGUAGE_LIST:
    case Constant.STORE_ACTIVE_SESSION:
    case Constant.GET_ALL_SIZING:
      return {
        ...state,
        loading: true,
      };
    case Constant.STORE_CALCULATION:
      return {
        ...state,
        calc_loading: true,
      };
    case Constant.STORE_SCHEMA_SUCCESS:
      const res: any = transform(action.data);
      const newRes = state.home.filter(function (obj: any) {
        return obj.step_order < res["step_order"];
      });
      const result = [...newRes, res].sort((a, b) =>
        a.step_order > b.step_order ? 1 : -1
      );
      return {
        ...state,
        loading: false,
        home: result,
      };
    case Constant.STORE_CALCULATION_SUCCESS:
      return {
        ...state,
        calc_loading: false,
        calculation: action.data,
      };
    case Constant.STORE_CALCULATION_ERROR:
      return {
        ...state,
        calc_loading: false,
        error: action.error,
      };
    case Constant.STORE_SIZING_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        sizing_results: action.data,
      };
    case Constant.STORE_PRODUCTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        production_list: action.data,
      };
    case Constant.STORE_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        languageList: action.data,
      };
    case Constant.GET_ALL_SIZING_SUCCESS:
      return {
        ...state,
        loading: false,
        allSizing: action.data,
      };
    case Constant.STORE_TOP_ANCHOR:
      return {
        ...state,
        loading: false,
        top_anchor: transformTopAnchor(action.top_anchor),
      };
    case Constant.STORE_UPDATE_TOP_ANCHOR:
      return {
        ...state,
        top_anchor: action.payload,
      };
    case Constant.STORE_LEFT_ANCHOR_SUCCESS:
      return {
        ...state,
        loading: false,
        left_anchor: transformLeftAnchor(action.left_anchor),
      };
    case Constant.STORE_GET_ELEMENT_DATA:
      return {
        ...state,
        loading: false,
        headerData: action.data,
      };
    case Constant.STORE_UPDATE_LEFT_ANCHOR:
      return {
        ...state,
        left_anchor: action.payload,
      };
    case Constant.STORE_UPDATE_LEFT_KEY_ANCHOR:
      let itemIndex = state.left_anchor.findIndex(
        (x: any) => x.ne_id === action.payload.ne_id
      );
      state.left_anchor[itemIndex][action.payload.key] = action.payload.value;

      state.left_anchor.forEach((element: any, i: number) => {
        if (i > itemIndex) {
          element.badges = [];
          element.enabled = false;
        }
      });
      return {
        ...state,
        left_anchor: state.left_anchor,
      };

    case Constant.UPDATE_RIGHT_DATA:
      return {
        ...state,
        right_anchor: action.payload,
      };
    case Constant.STORE_RIGHT_ANCHOR_SUCCESS:
      const ra_res: any = [];
      action.data.forEach((obj: any, i: number) => {
        ra_res.push(transform(obj));
      });
      // check if id have in state.right_anchor, if exit update the fields else push the fields
      if(state.right_anchor.some((item:any)=> item.id === ra_res[0].parent_ne_id)){
        state.right_anchor.forEach((element: any, i: number) => {
          if (element.id === ra_res[0].parent_ne_id) {
            element.fields = ra_res;
          }
        });
        return {
          ...state,
          loading: false,
          right_anchor: [
            ...state.right_anchor
          ],
        }
      }
      return {
        ...state,
        loading: false,
        right_anchor: [
          ...state.right_anchor,
          {
            id: ra_res[0].parent_ne_id,
            fields: ra_res,
            // group: 'group',
            // hr: true
          },
        ],
      };
    case Constant.STORE_UPDATE_RIGHT_ANCHOR:
      const right_index: number = state.right_anchor.findIndex(
        (x: any) => x.id === action.payload.ne_id
      );

      if (action.payload.value && action.payload.value[0]) {
        state.right_anchor[right_index] = action.payload.value[0];
      }

      state.right_anchor.forEach((element: any, i: number) => {
        if (i > right_index) {
          state.right_anchor.splice(i);
        }
      });
      return {
        ...state,
        right_anchor: state.right_anchor,
      };
    case Constant.RESET_RIGHT_ANCHOR_VALUES:
      state.right_anchor.splice(1);
      state.right_anchor.forEach((element: any) => {
        element.fields.map((obj: any) => {
          if (Object.prototype.toString.call(obj.value) == '[object String]' || Object.prototype.toString.call(obj.value) == '[object Number]') {
            obj.value = "";
          }
          if (Object.prototype.toString.call(obj.value) == "[object Array]") {
            obj.value = [];
          }
        });
      });
      state.left_anchor.forEach((element: any) => {
        element.badges = [];
      });
      return {
        ...state,
        right_anchor: state.right_anchor,
        left_anchor: state.left_anchor,
      };
    case Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR:
      const index: number = state.right_anchor.findIndex(
        (x: any) => x.id === action.payload.ne_id
      );
      let element_index = state.right_anchor[index].fields.findIndex(
        (x: any) => x.name === action.payload.name
      );

      if (action.payload) {
        state.right_anchor[index].fields[element_index] = {
          ...state.right_anchor[index].fields[element_index],
          ...action.payload,
        };
      }
      state.right_anchor.forEach((element: any, i: number) => {
        if (i > index) {
          state.right_anchor.splice(i);
        }
      });
      return {
        ...state,
        right_anchor: state.right_anchor,
      };
    case Constant.GET_UOM_DATA_SUCCESS:
      return {
        ...state,
        preferences:{
          ...state.preferences,
          ...action.data
        },
      };
    case Constant.PUT_UOM_DATA_SUCCESS:
      return {
        ...state,
        // updatedUomData: { data: action.data },
      };
    case Constant.PUT_UOM_DATA_ERROR:
      return {
        ...state,
        // updatedUomData: { data: action.data },
      };
    case Constant.STORE_SCHEMA_ERROR:
    case Constant.STORE_SIZING_RESULTS_ERROR:
    case Constant.STORE_LEFT_ANCHOR_ERROR:
    case Constant.STORE_RIGHT_ANCHOR_ERROR:
    case Constant.STORE_PRODUCTION_LIST_ERROR:
    case Constant.STORE_LANGUAGE_LIST_ERROR:
    case Constant.STORE_ACTIVE_SESSION_ERROR:
    case Constant.GET_ALL_SIZING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case Constant.CLEAR_RIGHT_ANCHOR:
      return {
        ...state,
        right_anchor: action.payload,
      };
    case Constant.UPDATE_CURRENT_NEID:
      let left_anchor = [...state.left_anchor];
      const left_index: number = left_anchor.findIndex(
        (x: any) => x.ne_id === action.payload
      );
      left_anchor.forEach((item, index)=>{
        item.selected = false;
        if (index == left_index) {
          item.selected = true;
        }
        if (index <= left_index) {
          item.enabled = true;
        }
      })
      return {
        ...state,
        left_anchor: left_anchor,
        defaultNeId: action.payload,
      };
    case Constant.STORE_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        activeSession: action.data,
      };
    case Constant.DELETE_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        activeSession: null
      }
    case Constant.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      }
    case Constant.UPDATE_SIZING_INPUT:
      return {
        ...state,
        sizingInputs: action.payload
      }
    case Constant.SET_REPORT_PAYLOAD:
      return {
        ...state,
        reportPayload: action.payload
      }
    case Constant.UPDATE_PREFERENCE_DATA:
      const {displayUnit, ...rest} = action.payload;
      if(displayUnit == 'English'){
        state.preferences.englishValue = rest;
      } else if(displayUnit == 'Metric'){
        state.preferences.metricValue = rest;
      } else {
        state.preferences.allValue = rest;
      }
      return {
        ...state,
        preferences: state.preferences,
        userPreferencesValue: action.payload
      }
    case Constant.UPDATE_BU_CODE:
      return {
        ...state,
        bu_code: action.payload
      }
    case Constant.UPDATE_LOGIN_STATE:
      return {
        ...state,
        loginState: action.payload
      }
    case Constant.UPDATE_USER_ID:
      return {
        ...state,
        userId: action.payload
      }
    case Constant.UPDATE_EDIT_SIZINGID:
      return {
        ...state,
        currentSizingId: action.payload
      }
    case Constant.UPDATE_ALERT_MESSAGE:
      return {
        ...state,
        alertErrorMsg: action.payload
      }
    case Constant.UPDATE_OPTIONS_BY_PREFERECES:
      if(state.right_anchor[2] && state.userPreferencesValue){
        state.right_anchor[2].fields.forEach((element: any) => {
          if(element.type == "TABLE_INPUT"){
            element.options.forEach((item: any) => {
              item.forEach((obj: any) => {
                if(obj.type == 'SINGLE_SELECT' && state.preferences.allOptions[obj.name] && !(obj.dynamicVariable == false)){
                  if(state.userPreferencesValue.displayUnit == 'English'){
                    obj.options = state.preferences.englishOptions[obj.name];
                  } else if(state.userPreferencesValue.displayUnit == 'Metric'){
                    obj.options = state.preferences.metricOptions[obj.name];
                  } else if(state.userPreferencesValue.displayUnit == 'All'){
                    obj.options = state.preferences.allOptions[obj.name];
                  } else {
                    obj.options = state.preferences.englishOptions[obj.name];
                  }
                  // update the value
                  if(action.payload != 'edit' && state.userPreferencesValue[obj.name]){
                    element.value[obj.name] = state.userPreferencesValue[obj.name];
                  }
                }
              })
            });
          }
        });
      }
      return {
        ...state,
        right_anchor: [...state.right_anchor]
      }
    default:
      return state;
  }
}
